<template>
  <p
    v-if="finalPrice.discount_type == 'percentage'"
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
    >
  

    <span class="percentage-rotate">
      
      {{ finalPrice.discount }}
    </span>
    <span >
      
      % 
    </span>
    <span>
      تخفیف
    </span>
  </p>
  <span
    v-else-if="finalPrice.discount_type == 'flat'"
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
  >
    تخفیف : {{ finalPrice.discount | price }} تومان
  </span>
  <span
    v-else-if="
      max_discount_variety && max_discount_variety.type == 'percentage'
    "
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
    >
    <!-- {{ max_discount_variety.value }}% تخفیف -->
    <span class="percentage-rotate">
      
      {{ max_discount_variety.value }}
    </span>
    <span >
      
      % 
    </span>
    <span>
      تخفیف
    </span>

    </span
  >
  <span
    v-else-if="max_discount_variety && max_discount_variety.type == 'flat'"
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
  >
    تخفیف : {{ max_discount_variety.value | price }} تومان
  </span>
</template>

<script>
export default {
  name: "DiscountTag",
  props: {
    finalPrice: Object,
    max_discount_variety: Object,
  },
};
</script>

<style scoped>
.percentage-rotate {
  /*transform: rotate(90deg);*/
  display: inline-block;
}
</style>
